// extracted by mini-css-extract-plugin
export var centered = "+-OfU";
export var header = "_4dQNe";
export var logo = "WAoX3";
export var menuContact = "ns-rJ";
export var menuImages = "jpkqi";
export var menuItem = "_902FT";
export var menuJobs = "+RSMW";
export var menuMaintainer = "yBG55";
export var menuPosts = "Ed2oO";
export var menuSchool = "IfELb";
export var mobileMenuTrigger = "LD+wR";
export var navigation = "Ri0Ak";
export var search = "Hpy6K";
export var searchField = "kvEtD";
export var selected = "fxo5A";
export var subMenu = "bxeyR";
export var triggerCheckbox = "_52B3k";