// extracted by mini-css-extract-plugin
export var centered = "DmdUE";
export var colorBlue = "lTC86";
export var colorColdRainbow = "TcyE9";
export var colorGold = "nnPLX";
export var colorGreen = "yo3E9";
export var colorLilac = "ofYSR";
export var colorPink = "_1y95-";
export var colorWarmRainbow = "DVggf";
export var hero = "_6D8n+";
export var lead = "e3Vu7";
export var nonPaged = "kBvsj";
export var page = "qHlir";
export var pager = "GRtP4";
export var pagerLeft = "CrI4U";
export var pagerRight = "HmWAK";
export var show = "jkeG8";
export var title = "ECmwx";